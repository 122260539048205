class IncomeYearly extends HTMLElement {
  connectedCallback() {
    const incomeYearly = this.querySelector('input[name*="[income_yearly]"]');
    const base = this.querySelector(
      'input[name*="[base_monthly_salary_and_overtime_pay]"]',
    );
    const bonus = this.querySelector('input[name*="[bonus]"]');
    const stockOptions = this.querySelector('input[name*="[stock_options]"]');
    const stockOptionsBlock = this.querySelector(".stock_options");
    const restrictedStockUnits = this.querySelector(
      'input[name*="[restricted_stock_units]"]',
    );
    const restrictedStockUnitsBlock = this.querySelector(
      ".restricted_stock_units",
    );
    this.addEventListener("input", (event) => {
      setIncomeYearly();
    });

    this.querySelector('select[name*="[stock_type]"]').addEventListener(
      "input",
      (event) => {
        setStockOptions(event.target.value);
      },
    );

    const setIncomeYearly = () => {
      incomeYearly.value =
        Number(base.value) +
        Number(bonus.value) +
        Number(stockOptions.value) +
        Number(restrictedStockUnits.value);
      this.querySelector(".income-yearly").innerHTML = incomeYearly.value;
    };

    const setStockOptions = (targetValue) => {
      if (targetValue === "stock_options") {
        stockOptionsBlock.classList.remove("hidden");
        stockOptions.setAttribute("required", "true");
        restrictedStockUnitsBlock.classList.add("hidden");
        restrictedStockUnits.removeAttribute("required");
        restrictedStockUnits.value = "";
      } else if (targetValue === "restricted_stock_units") {
        restrictedStockUnitsBlock.classList.remove("hidden");
        restrictedStockUnits.setAttribute("required", "true");
        stockOptionsBlock.classList.add("hidden");
        stockOptions.removeAttribute("required");
        stockOptions.value = "";
      } else {
        stockOptionsBlock.classList.add("hidden");
        stockOptions.removeAttribute("required");
        stockOptions.value = "";
        restrictedStockUnitsBlock.classList.add("hidden");
        restrictedStockUnits.removeAttribute("required");
        restrictedStockUnits.value = "";
      }
    };

    setIncomeYearly();
    setStockOptions(this.querySelector('select[name*="[stock_type]"]').value);
  }
}
customElements.define("om-income-yearly-field", IncomeYearly);
