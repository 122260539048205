class Birthday extends HTMLElement {
  connectedCallback() {
    const year = this.querySelector("input.year");
    const month = this.querySelector("input.month");
    const date = this.querySelector("input.date");
    const birthday = this.querySelector("input.birthday");

    Array.from([year, month, date]).forEach((element) => {
      element.addEventListener("input", (event) => {
        maxLength(event);
        if (!event.target.validity.valid) {
          element.setAttribute("aria-invalid", "true");
          this.querySelector(".errors").innerHTML =
            "生年月日を正しく入力してください";
        } else if (isInvalidDate(year.value, month.value, date.value)) {
          element.setAttribute("aria-invalid", "true");
          this.querySelector(".errors").innerHTML =
            "生年月日を正しく入力してください";
        } else {
          year.setAttribute("aria-invalid", "false");
          month.setAttribute("aria-invalid", "false");
          date.setAttribute("aria-invalid", "false");
          this.querySelector(".errors").innerHTML = "";
        }

        setBirthDay(year, month, date);
      });
    });

    date.addEventListener("blur", (event) => {
      if (isInvalidDate(year.value, month.value, date.value)) {
        date.setAttribute("aria-invalid", "true");
        elm.querySelector(".error").innerHTML =
          "生年月日を正しく入力してください";
      }
    });

    Array.from([month, date]).forEach((c) => {
      c.addEventListener("blur", (e) => {
        if (e.target.validity.valid && e.target.value.length == 1) {
          e.target.value = "0" + e.target.value;
          e.target.dispatchEvent(new Event("input"));
        }
      });
    });

    const setBirthDay = (year, month, date) => {
      birthday.value = year.value + "/" + month.value + "/" + date.value;
    };

    const maxLength = (event) => {
      const maxLength = event.target.maxLength;
      if (maxLength < 1) return;
      if (event.target.value.length > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
      }
    };

    const isInvalidDate = (year, month, date) => {
      if (year != "" && month != "" && date != "") {
        const d = new Date(year, month - 1, date);
        if (Number.isNaN(d.getTime())) return true;
        if (d.getMonth() !== month - 1) return true;
      }
      return false;
    };
  }
}
customElements.define("om-birthday-field", Birthday);
