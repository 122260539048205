class Header extends HTMLElement {
  connectedCallback() {
    const searchForm = this.querySelector("#om-header-search-form");
    if (searchForm) {
      const searchIcon = searchForm.querySelector("svg");
      const searchInput = searchForm.querySelector("input");
      const naviSearch = this.querySelector("#om-header-navi-search");
      const logo = this.querySelector("#om-header-logo");

      searchIcon.addEventListener("click", (event) => {
        naviSearch.classList.remove("hidden");
        searchForm.classList.add("hidden");
        logo.classList.remove("hidden");
      });

      naviSearch.addEventListener("click", (event) => {
        naviSearch.classList.add("hidden");
        searchForm.classList.remove("hidden");
        searchInput.focus();
        logo.classList.add("hidden");
      });

      searchInput.addEventListener("blur", (event) => {
        naviSearch.classList.remove("hidden");
        searchForm.classList.add("hidden");
        logo.classList.remove("hidden");
      });
    }
  }
}
customElements.define("om-header", Header);
