import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faUserAlt,
  faLock,
  faSearch,
  faUserCog,
  faSignOutAlt,
  faEdit,
  faBars,
  faChevronRight,
  faChevronLeft,
  faHome,
  faHashtag,
  faArrowDown,
  faArrowUp,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faTimes,
  faExclamationCircle,
  faGraduationCap,
  faCircleNotch,
  faCheckCircle,
  faCommentDots,
  faStar,
  faStarHalfAlt,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import {
  faClock,
  faEnvelope,
  faStar as farFaStar,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faUserAlt,
  faLock,
  faSearch,
  faUserCog,
  faSignOutAlt,
  faEdit,
  faBars,
  faClock,
  faEnvelope,
  faChevronRight,
  faChevronLeft,
  faHome,
  faHashtag,
  faArrowDown,
  faArrowUp,
  faFacebookF,
  faTwitter,
  faGoogle,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faTimes,
  faExclamationCircle,
  faGraduationCap,
  faCircleNotch,
  faCheckCircle,
  faCommentDots,
  faStar,
  farFaStar,
  faStarHalfAlt,
  faExternalLinkAlt,
);
dom.watch();
